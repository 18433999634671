import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { TroisJSVuePlugin } from 'troisjs';
import './assets/index.scss';



const application = createApp(App)

application
  .use(store)
  .use(router)
  .use(TroisJSVuePlugin)
  .mount('#app')
