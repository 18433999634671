<template>
  <div>
    <section class="testimonials">
      <h1>Client's testimonials</h1>
      <div class="carousel_wrapper">
        <div id="testimonials-carousel" class="carousel_container">
          <div class="cards"  v-for="testimonial in testimonials" :key="testimonial">
            <ComentCard :comment="testimonial.comment" :fullName="testimonial.fullName" :imageUrl="testimonial.iconUrl" :location="testimonial.location"/>
          </div>
        </div>
      </div>
        <div class="carousel_navigation">
          <button class="btn btn_left" 
          :disabled="carouselBtnLeft ? disabled : ''"
          @click="carouselMoveLeft"
          >
            <ion-icon name="arrow-back-outline"></ion-icon>
          </button>
          <button class="btn btn_right"
          :disabled="carouselBtnRight ? disabled : ''"
          @click="carouselMoveRight"
          >
            <ion-icon name="arrow-forward-outline"></ion-icon>
          </button>
        </div>
    </section>
  </div>
</template>

<script>
import ComentCard from './ComentCard.vue'
  export default {
    data(){
      return{
        carouselContainer: null,
        carouselBtnLeft: false,
        carouselBtnRight: true,
        carouselPosition: 0,
        windowWidth: window.innerWidth,
        carouselDisplayedElements: 0,
        testimonials: [
          {iconUrl:'../../public/images/test_image.jpg', comment: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui ipsam, iure eveniet sequi temporibus, ut maxime soluta accusamus tenetur quasi reiciendis neque eius voluptatum doloribus.', fullName: 'Roman Sakhnov', location: 'Wawa'},
          {iconUrl:'../../public/images/test_image.jpg', comment: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui ipsam, iure eveniet sequi temporibus, ut maxime soluta accusamus tenetur quasi reiciendis neque eius voluptatumdolor sit amet consectetur adipisicing elit. Qui ipsam, iure eveniet sequi temporibus, ut maxime soluta accusamus tenetur quasi reiciendis neque eius voluptatum doloribus.', fullName: 'Roman Sakhnov', location: 'Wawa'},
          {iconUrl:'../../public/images/test_image.jpg', comment: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui ipsam, iure eveniet sequi temdolor sit amet consectetur adipisicing elit. Qui ipsam, iure eveniet sequi temporibus, ut maxime soluta accusamus tenetur quasi reiciendis neque eius voluptatumdolor sit amet consectetur adipisicing elit. Qui ipsam, iure eveniet sequi temporibus, ut maxime soluta accusamus tenetur quasi reiciendis neque eius voluptatumporibus, ut maxime soluta accusamus tenetur quasi reiciendis neque eius voluptatum doloribus.', fullName: 'Roman Sakhnov', location: 'Wawa'},
          {iconUrl:'../../public/images/test_image.jpg', comment: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui ipsam, iure eveniet sequi temporibus, ut maxime soluta accusamus tenetur quasi reiciendis neque eius voluptatum doloribus.', fullName: 'Roman Sakhnov', location: 'Wawa'},
          {iconUrl:'../../public/images/test_image.jpg', comment: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui ipsam, iure eveniet sequi temporibus, ut maxime soluta accusamus tenetur quasi reiciendis neque eius voluptatum doloribus.', fullName: 'Roman Sakhnov', location: 'Wawa'},
          {iconUrl:'../../public/images/test_image.jpg', comment: 'Lorem ipsum dolor sit adolor sit amet consectetur adipisicing elit. Qui ipsam, iure eveniet sequi temporibus, ut maxime soluta accusamus tenetur quasi reiciendisamus tenetur quasi reiciendis neque eius voluptatumdolor sit amet consectetur adi temporibus, ut maxime soluta accusamus tenetur quasi reiciendisamus tenetur quasi reiciendis neque eius voluptatumdolor sit amet consectetur adi temporibus, ut maxime soluta accusamus ti ipsam, iure eveniet sequi temporibus, ut maxime soluta accusamus tenetur quasi reiciendis neque eius voluptatummet consectetur adipisicing elit. Qui ipsam, iure eveniet sequi temporibus, ut maxime soluta accusamus tenetur quasi reiciendis neque eius voluptatum doloribus.', fullName: 'Roman Sakhnov', location: 'Wawa'},
          {iconUrl:'../../public/images/test_image.jpg', comment: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui ipsam, iure eveniet sequi temporibus, ut maxime soluta accusamus tenetur quasi reiciendis neque eius voluptatum doloribus.', fullName: 'Roman Sakhnov', location: 'Wawa'},
        ]
      }
    },
    mounted() {
      this.carouselConfiguration();
      this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
    },
    methods:{
      carouselConfiguration(){
        this.carouselContainer = document.getElementById("testimonials-carousel");
        if (this.windowWidth<=1150) {this.carouselDisplayedElements = 1} else {this.carouselDisplayedElements = 2}
      },
      carouselMoveLeft(){
        if(this.carouselPosition>0){
          if (!this.carouselBtnRight) {this.carouselBtnRight = true}
          this.carouselPosition-=1;
          if (this.carouselPosition<=0) {this.carouselBtnLeft = false}
          this.carouselCalculate();
        }
      },
      carouselMoveRight(){
        if(this.carouselPosition<this.testimonials.length-(1*this.carouselDisplayedElements)){
          if (!this.carouselBtnLeft) {this.carouselBtnLeft = true}
          this.carouselPosition+=1;
          if (this.carouselPosition>=this.testimonials.length-(1*this.carouselDisplayedElements)) {this.carouselBtnRight = false}
          this.carouselCalculate();
        }
      },
      carouselCalculate(){
        this.carouselContainer.style.transform ='translateX(-'+(100/this.carouselDisplayedElements)*this.carouselPosition+'%)'
      },
      onResize() {
        this.windowWidth = window.innerWidth

        if (this.windowWidth<=1150) {this.carouselDisplayedElements = 1} else {this.carouselDisplayedElements = 2}
      },
      carouselReset() {
        this.carouselPosition = 0;
        this.carouselBtnLeft=false;
        this.carouselBtnRight=true;
        this.carouselCalculate();
      }

    },
    watch:{
      carouselDisplayedElements(newValue, oldValue){
        if (newValue != oldValue){
          this.carouselReset();
        }
      }
    },
    beforeUnmount() { 
        window.removeEventListener('resize', this.onResize); 
    },
    components:{
      ComentCard
    }
  }
</script>

<style lang="scss" scoped>
.testimonials{
  max-width: 1440px;
  margin: auto;
  margin-top: 100px;
  position: relative;
}
.carousel{
  &_wrapper{
    position: relative;
    overflow: hidden;
    max-width: 1440px;
    margin: auto;
    margin-top: 50px;
    padding: 0 40px;
  }
  &_container{
    transition: 1s;
    display: -webkit-box;
    .cards{
      width: 50%;
      display: flex;
      justify-content: center;
      margin: 100px 0 50px;
    }
  }
  &_navigation{
    padding: 0 10px;
    position: absolute;
    width: 100%;
    height: 100%;
    max-height: 300px;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    button{
      &:hover{
        background-color: #f8f8f8;
        ion-icon{
        filter: drop-shadow(0 0 5px rgb(32, 191, 240));
        }
      }
      width: 70px;
      height: 70px;
      box-shadow: 0 30px 35px rgba(31, 110, 227, 0.15);
      background-color: #ffffff;
      border-radius: 100%;
      display: flex;
      transition: 0.4s;
      justify-content: center;
      align-items: center;
      ion-icon{
        color: #2fc9ef;
        font-size: 30px;
        transition: 0.4s;
      }
    }
  }
}

@media (max-width: 1150px) {
  .carousel{
    &_container{
      .cards{width:100%}
    }
    &_navigation{
      button{
      margin-left: 15px;
      margin-right: 15px;
      }
    }
  }
  
}

</style>