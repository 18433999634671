<template>
  <div>
    <div class="skill-card">
      <div class="skill-card_container">
        
        <div class="skill-card_container__iconbox">
          <div class="img">
            <img :src="cardIconUrl" alt="">
          </div>
          <div class="number">
            <h1 v-if="id<10">0{{id}}</h1>
            <h1 v-else>{{id}}</h1>
          </div>
        </div>
        <div class="skill-card_container__textbox">
          <h1>{{ cardHeader }}</h1>
          <p>{{ cardDescription }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props:{
      id: Number,
      cardHeader: String,
      cardDescription: String,
      cardIconUrl: String,
    },
  }
</script>

<style lang="scss" scoped>

.skill-card {
  max-width: 550px;
  width: 100%;
  height: 600px;
  padding: 55px 81px 105px 72px;
  box-shadow: 0 27px 20px rgba(207, 216, 220, 0.45);
  border-radius: 20px 0 20px 20px;
  background-color: #ffffff;
  margin: 0 5px 65px;
  &_container{
    &__iconbox{
      display: flex;
      align-items: center;
      position: relative;
      max-height: 150px;
      height: 100vh;
      margin-bottom: 56px;
      .number{
        position: absolute;
        top: 55px;
        right: 0;
      }
      h1 {
        opacity: 0.3;
        color: #cfd8dc;
        font-family: 'Inter', 'Black';
        font-size: 200px;
        font-weight: 400;
        line-height: 36px;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
      }
    }
    &__textbox{
      height: 199px;
      font-family: "Inter";
      font-weight: 500;
      font-style: normal;
      line-height: 35.94px;
      text-align: left;
      font-size: 18px;
      h1{
        color: #263238;
        font-family: "Inter", "Bold";
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 40px;
      }
      p{
        font-family: "Inter", "Medium";
        font-weight: 500;
        font-style: normal;
        line-height: 35.94px;
      }
    }
  }
}
</style>