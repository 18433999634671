<template>
  <div class="home">
    <!-- <TestScene/> -->
    <Intro/>
    <OurSkills/>
    <Testimonials/>
    <ContactForm/>
  </div>
</template>

<script>
// @ is an alias to /src
// import TestScene from '@/components/TestScene.vue'
import Intro from '@/components/Intro.vue'
import ContactForm from '@/components/ContactForm.vue'
import Testimonials from '@/components/Testimonials.vue'
import OurSkills from '@/components/OurSkills.vue'


export default {
  name: 'Home',
  components: {
    // TestScene,
    Intro,
    ContactForm,
    Testimonials,
    OurSkills
  },
  
}
</script>

<style lang="scss" scoped>

</style>
