<template>
  <div>
    <nav class="nav">
      <div class="nav_logo">A</div>
      <button class="nav_btn" @click="togglemodal"><ion-icon name="menu-outline"></ion-icon></button>
      <div id="nav-collapse" class="nav_site-links">
        <div class="nav_links">
          <ul>
            <li><router-link to="/">Home</router-link></li>
            <li><router-link to="/about">About</router-link></li>
          </ul>
        </div>
        <ul>
          <li>Our company</li>
          <li>Our experts</li>
          <li>Our values</li>
          <li>Contact</li>
          <li>Search</li>
        </ul>
      <div @click="closeModal" class="nav_background"></div>
      </div>
    </nav>
  </div>
</template>

<script>
  export default {
    data() {
      return{
        active: false,
        navElement: null,
      }
    },
    methods: {
      togglemodal(){
        this.navElement.classList.toggle("active")
      },
      closeModal(){
        this.navElement.classList.remove("active")
      }
    },
    mounted() {
      this.navElement = document.getElementById('nav-collapse')
    }
  }
</script>

<style lang="scss" scoped>
.nav{
  z-index: 200;
  max-width: 1440px;
  width: 100%;
  margin: auto;
  &_logo{
    margin-left: 120px;
    width: 72px;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: #fb7dc4;
    font-size: 40px;
    border-radius: 100%;
  }
}
@media (max-width:1080px) {
  .nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    z-index: 200;
    position: fixed;
    &_logo{
      z-index: 300;
    }
    &_btn{z-index:300;}
    &_site-links{
      top: -150%;
      transition: 0.5s;
      position: fixed;
      width: 100%;
      background-color: rgb(255,255,255,0.65);
      z-index: 250;
      ul {
        list-style: none;
        text-align: left;
        padding: 10px 20px;
        li{
          margin-top: 10px;
          padding: 15px 30px;
          border: 1px solid blue;
          border-radius: 5px;
          background-color: white;
          color: black;
          a{
            color: black;
            text-decoration: none;
          }
        }
      }
    }
    &_background{
      top: -100%;
      transition: 0.8s;
      opacity: 0.3;
      position: fixed;
      width:100%;
      height: 100%;
      background-color: gray;
      z-index: -1;
    }
    &_btn{
      margin-right: 30px;
      border: 2px solid white;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 45px;
      height: 45px;
      border-radius: 5px;
      background-color:#4899e9;
      transition: 0.3s;
      &:hover{
        background-color: #2f6397;
      }
      ion-icon{
        color:white;
        font-size: 50px;
      }
    }
    &_logo{
      margin-left: 30px;
    }

  }
.active{
  .nav_background{
    top: 0%;
  }
  top: 100px;  
}
  
}
@media (min-width:1080px) {
.nav{
  margin: auto;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
  color: white;
  &_btn{
    display: none;
  }
  margin-top: 70px;
  &_site-links{ 
    display: flex;
    flex-wrap: wrap;
  }
  &_links{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  ul{
      display: inline-flex;
      list-style: none;
      margin: 0;
      li{
        padding: 0 15px;
        a{
          color: white;
          text-decoration: none;
        }
      }
    }
  }
}


</style>