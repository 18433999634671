<template>
  <footer>
    <div class="footer-wave"></div>

    <div class="footer-container">
      


      <div class="row wrapper">

        <div class="col">
          <div class="row footer-container_box"> 
            <div class="col-3">
              <div class='footer-container_box__logo'><h1>A</h1></div>
            </div>
            <div class="col footer-container_box__text">
              <p>Arda Real Estate Consulting provides independent research and consulting services related to the US housing industry.</p>
              <p>Our experienced team of analysts and consultants helps our clients identify the best risk-adjusted investment opportunities.</p>
            </div>
          </div>
        </div>
      
        <div class="col-md">
          <div class="row footer-container_links">
            <div class="col wrapper">
              <h1>OUR COMPANY</h1>
              <ul>
                <li>Our Company</li>
                <li>About us</li>
                <li>Research</li>
                <li>Consulting</li>
                <li>Carees</li>
              </ul>
            </div>
            <div class="col wrapper">
              <h1>OUR EXPERTS</h1>
              <ul>
                <li>Team</li>
                <li>Coverage</li>
              </ul>
            </div>
            <div class="col wrapper">
              <h1>OUR VALUES</h1>
              <ul>
                <li>Proprietary Tools</li>
                <li>Forecasting</li>
                <li>Surveys</li>
                <li>Surban</li>
                <li>Demographics</li>
                <li>DesignLens</li>
              </ul>
            </div>
            <div class="col wrapper">
              <h1>CONTACT</h1>
              <ul>
                <li>Contact Us</li>
                <li>Office Locations</li>
                <li>Newsetter Sign-Up</li>
                <li>Social Media</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="copyrights">
          <div class="col">
            <p>Arda Real Estate Consulting, LLC<br>
            Copyright 2018</p>
          </div>
          <div class="col buttons">
            <button>i</button>
            <button>f</button>
            <button>t</button>
          </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style lang="scss" scoped>
footer{
  z-index: 0;
  margin-top: 150px;
  background-image: linear-gradient(to bottom, #427be3, #4ba7ec);
  color: white;
  overflow: hidden;
  .footer-container{
    margin: auto;
    max-width: 1440px;
    padding: 0 15px;
    width: 100%;

    &_box{
      max-width: 500px;
      &__logo{
        display: flex;
        justify-content: center;
        background-color: #fb7dc4;
        border-radius: 100%;
        width: 65px;
        height: 65px;
        h1 {
          align-self: center;
        }
      }
      &__text{
        max-width: 375px;
        p {
          text-align: start;
          margin-bottom: 40px;
        }
      }
    }
    &_links {
      min-width: fit-content;
      text-align: start;
      font-size: 15px;
        .wrapper{
          min-width: fit-content;
        }
      h1{
        font-size: 18px;
        padding-bottom: 30px;
      }
      ul{
        list-style: none;
        padding: 0; 
        li{
          padding: 5px 0;
        }
      }
    }
    .copyrights{
      margin-top: 50px;
      height: 140px;
      width: 100%;
      display: flex;
      align-items: center;
        .col {
          align-items: center;
          padding: 0;
        }
        p{
          font-size: 14px;
          line-height: 25px!important;
          text-align: start;
          margin: 0!important;
        }
        .buttons {
          display: flex;
          justify-content: right;
          padding: 0;
        }
        button {
          cursor: pointer;
          border-radius: 100%;
          width: 47px;
          height: 47px;
          padding: 0;
          margin-left: 9px;
          border: none;
          color: #499cea;
          fill:#499cea;
          background-color: white;
          justify-self: end;
          transition: 0.5s;
        }
        button:hover{
          color: white;
          background-color: aqua;
        }
      
    }
  }
}


@media (max-width: 1200px){
  .footer-container_box{
      margin: auto;}
  .wrapper{
    display: inline-block;
  }
}

.footer-wave{

  background: no-repeat;
  background-image: url('../../public/images/footer-top-curved-lines.svg');
  background-position-x: center;
  background-position-y: bottom;
  background-size: cover;
  max-height: 200px;
  height: 100%;
  position: absolute;
  transform: translate(0, -99%);
  z-index: -1;
  min-width: 700px;
  width: 100%;
}


  </style>