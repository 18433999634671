<template>
  <div>
    <div class="intro">
      <div class="intro_container">
        <div class="intro_container__content">
          <h1>Expert advice for<br>discerning buyers</h1>
          <p>Our consultants and research analysts work together<br>to provide understanding and clarity through the<br>intricacies of an ever changing reeal estate cycle.</p>
          <button>Learn more<ion-icon name="arrow-forward-outline"></ion-icon></button>
        </div>
        <div class="intro_wrapper">
          <div class="intro_container__image"><img src="../../public/images/intro-main-image.png" alt=""></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>
.intro{
  min-width: 700px;
  color: white;
  overflow-x: clip;
  padding-top: 100px;
  &_container{
    max-width: 1440px;
    margin: auto;
    width: 100%;
    padding-top: 110px;
    position: relative;
    display: flex;
    &::before{
      position: absolute;
      top: 0;
      left: 0;
      z-index:-1;
      content: url('../../public/images/intro-background-blob.png');
      transform: translate(-24%, -57%);
    }
    
    &__image{
        position: absolute;
        top: 0;
        right: 0;
        width: 60%;
        transform: translate(10%, -5%);
      img{
        width: -webkit-fill-available;
      }
    }
    &__content{
      padding-left:120px;
      z-index: 100;
      text-align: left;
      h1{
        font-size: 50px;
        font-weight: 400;
      }
      p{
        margin-top: 50px;
      }
      button{
        margin-top: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        width: 220px;
        height: 75px;
        box-shadow: 0 30px 35px rgba(31, 110, 227, 0.15);
        border-radius: 20px 0 20px 20px;
        background-color: #ffffff;
        ion-icon{
          padding-left: 15px
        }
      }
    }
  }
}
@media (max-width: 1100px) {
  .intro{
    &_wrapper{
        margin-bottom: -150px;
      }
    &_container{
    flex-direction: column-reverse;
      &::before{
        transform: translate(-20%, -40%)
      }
    
    
    &__image{
      transform: unset;
      margin: auto;
      position: relative;
      img{
        transform: translate(0px, -200px);
        width: -webkit-fill-available;
      }
    }
    &__content{
      padding-left: unset;
      z-index: 100;
      text-align: left;
      width: fit-content;
      margin: auto;
      h1{}
      p{}
      button{}
      }
    }
  }
}

@media (max-width: 850px) {
  .intro{
    padding-top: 120px;
    &_container{
      &::before{
        transform: translate(-24%, -48%)
      }
    }
  }
}
</style>