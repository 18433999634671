<template>
  <div>
    <section class="our-skills">
      <div class="our-skills_header">
        <h1>Why Arda<br> Real Estate Consulting?</h1>
      </div>
      <div class="our-skills_contentbox">
        <div v-for="skill in skills" :key="skill.id" >
          <skill-card :cardDescription="skill.description" :cardHeader="skill.header" :cardIconUrl="skill.iconUrl" :id="skill.id"/>
        </div>
      </div>
      <div class="our-skills_contact">
        <button class="our-skills_contact__btn">Contact us now<ion-icon name="arrow-forward-outline"></ion-icon></button>
      </div>
    </section>
  </div>
</template>

<script>
import SkillCard  from './SkillCard.vue'
import ExcelentReputation from '../../public/images/excelent-reputation.svg'
import ExperiencedTeam from '../../public/images/experienced-team.svg'
import NationalFootprint from '../../public/images/national-footprint.svg'
import ExcelentService from '../../public/images/excelent-service.svg'
console.log(typeof(ExcelentReputation), ExcelentReputation)
  export default {
    data() {
      return {
        skills: [
          {id: 1, header: 'Excelent reputation.', description: 'The Arda Real Estate Consulting reputation among investors was formed by our Research group and has been reinforced by our Consulting team by providing well thought-out, unbiased commendations and advice.', iconUrl: ExcelentReputation},
          {id: 2, header: 'Experienced Team.', description: 'Our Consulting group is led by a senior management team each with 20+ years of direct executive level homebuilding, development, and consulting experience.', iconUrl: ExperiencedTeam},
          {id: 3, header: 'National Footprint.', description: 'Many of our clients operate throughout the country. We are one of the largest and most geographically diverse consulting groups which allows us to best service their needs.', iconUrl: NationalFootprint},
          {id: 4, header: 'Excelent Service.', description: 'Often times our clients do not have full control over timing for their deals and the Consulting team has a knack for making miracles happen.', iconUrl: ExcelentService},
        ]
      }
    },
    components:{
      SkillCard
    }
  }
</script>

<style lang="scss" scoped>
.our-skills {
  min-width: 700px;
  position: relative;
  overflow-x: clip;
  margin-bottom: 100px;
  margin-top: 180px;
  z-index: -1;
  padding-bottom: 700px;
  overflow-y: clip;
  &::before{
    opacity: 1;
    position: absolute;
    top: 0;
    z-index: -1;
    content: "";
    width: 3600px;
    height: 2000px;
    background: url('../../public/images/skills-background-blob.svg');
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-size: cover;
    transform: translate(-32.6%, 6%);
  }
  &::after{
    position: absolute;
    top:0;
    z-index:-1;

    width: 4000px;
    height: 2600px;
    background-position-x: center;
    transform: translate(-50%, -40%);  
    content: '';
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.05));
  }
  &_header{
    width: 453px;
    margin: auto;
    h1{
      color: #263238;
      text-align: center;
      font-family: Inter;
      font-size: 36px;
      line-height: 50px;
      font-weight: bold;
      }
  }
  &_contentbox{
    display: flex;
    flex-wrap: wrap;
    max-width: 1440px;
    width: 100%;
    margin: auto;
    justify-content: space-around;
    align-content: space-between;
    margin-top: 100px;
    padding: 0 115px;
    z-index: 0;
    
  }
  &_contact{
    position: relative;
    display: flex;
    justify-content: center;
    &::after{
      position: absolute;
      top: 0;
      z-index: 0;
      content: url('../../public/images/skills-contact-btn-background.png');
      transform: translate(0, -40%);
      z-index: -1;
    }
    button{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 280px;
      height: 75px;
      border: none;
      box-shadow: 0 30px 35px rgba(47, 201, 239, 0.15);
      border-radius: 20px 0 20px 20px;
      background-color: #2fc9ef;
      color: white;
      font-size: 20px;
      font-weight: 700;
      font-style: normal;
      letter-spacing: normal;
      line-height: 40px;
      ion-icon{
        padding-left: 15px;
      }
    }
  }
}

@media (max-width: 1000px) {
  .our-skills{
    &_contentbox{
      padding: 0 40px!important;
    }
  }
}
</style>