<template>
  <Header/>
  <router-view/>
  <Footer/>
</template>

<script>

import Header from "@/components/Header.vue"
import Footer from "@/components/Footer.vue"
export default {
  name: "app",
  components: {
    Header,
    Footer
  }
}
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
body{ 
  margin: 0;
}
#app {
  font-family: 'Inter', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-width: 700px;
}


</style>
