<template>
  <div>
    <div class="card">
      <div class="card_container">
        <div class="card_container__image"><img src="../../public/images/test_image.jpg" alt=""></div>
        <div class="card_container__textbox">
          <p name="description">{{ comment }}</p>
          <h1 name="fullName">{{ fullName }}</h1>
          <h1 name="location">{{ location }}</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props:{
      imageUrl: String,
      comment: String,
      fullName: String,
      location: String,
    }
  }
</script>

<style lang="scss" scoped>
.card{
  width: 550px;
  box-shadow: 0 27px 20px rgba(207, 216, 220, 0.45);
  border-radius: 20px 20px 60px;
  background-color: #ffffff;
  padding: 94px 79px 65px 69px;
  position: relative;
  &_container{
    &__image {
      position: absolute;
      top:0;
      transform: translate(0, -50%);
      border-radius: 100%;
      width: 100px;
      height: 100px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 27px 20px rgba(207, 216, 220, 0.65);

      img{
      width: 100%
      }
    }
    &__textbox{
      text-align: left;
      p{
        color: #546e7a;
        font-family: "Inter UI";
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        letter-spacing: normal;
        line-height: 30px;
      }
      h1[name="fullName"]{
        color: #2fc9ef;
        font-family: "Inter UI - Bold";
        font-size: 20px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: normal;
        line-height: 35.94px;
      }
      h1[name="location"]{
        color: #263238;
        font-family: "Inter UI - Bold";
        font-size: 18px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: normal;
        line-height: 35.94px;
      }
    }

  }
}
</style>