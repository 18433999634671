<template>
  <div>
    <div class="contact">
      <div class="contact_box">
        <div class="contact_text">
          <h1>Let us hear your thoughts</h1>
          <p>If you have any quastions about our consulting serrvices, please contact us</p>
        </div>
        <form action="">
            <div class="form-group form-group_name">
              <label for="">Full name</label>
              <input type="text" placeholder="How we should call you?">
            </div>
            <div class="form-group form-group_email">
              <label for="">Email Address</label>
              <input type="email" name="" id="" placeholder="Enter a valid email address">
            </div>
            <div class="form-group form-group_message">
              <label for="">Your messsage</label>
              <textarea name="" id=""   placeholder="Tell us more about your project"></textarea>
            </div>
          <button type="submit" >Submit<ion-icon name="arrow-forward-outline"></ion-icon></button>
        </form>
      <div class="contact_map_container">
        <div class="contact_map">
          <div class="contact_map__text">
            <h1>ITmove</h1>
            <p>Polska, Warszawa <br> ul. Trakt Lubelski 249a</p>
          </div>
          <div class="contact_map__wrapper"></div>
          </div>
        </div>  
      </div>
    </div>
  </div>
</template>

<script>
export default {
  setup () {
    

    return {}
  }
}
</script>

<style lang="scss" scoped>
.contact {    
  max-width: 1440px;
  padding: 0 10px;
  margin: auto;
  position: relative;
  &_box{
    color: white;
    max-width: 1290px;
    padding-top: 100px;
    padding-left: 90px;
    padding-right: 450px;
    padding-bottom: 90px;
    border-radius: 10px 0 10px 10px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);

    background-image: url('../../public/images/contact-form-background.svg');
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
  }
  &_text{
    max-width: 360px;
    text-align: left;
    h1{
      font-weight: 900;
    }
  }
  &_map{
    &_container{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 100%;
      max-width: 525px;
      width: 100%;
      position: absolute;
      top: 0;
      right: 10px;
    }
    overflow: hidden;
    width: 100%;
    max-height: 590px; 
    height: 100%;
    border-radius: 10px 0 10px 10px;
    box-shadow: -5px 42px 35px rgba(31, 110, 227, 0.45);
    background-image: url('../../public/images/contact-form-background.svg');
    display: flex;
      justify-content: left;
      align-items: flex-end;
    &__wrapper{
      z-index:1;
      background-image: url('../../public/images/map-position.png');
      background-position: 0px -33px;
      animation:1s chevron-leave;
      width: 100%;
      height: 100%;
      transition: 0s;
      opacity: 0;
      transition: 1s;

    }
    &__text{
      position: absolute;
      color: white;
      z-index: 100;
      text-align: left;
      text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
      padding: 15px 25px;
    }
  }
  &_map:hover{ 
    .contact_map__wrapper{
      opacity: 1;
      background-image: url('../../public/images/map-position.png');
      animation:1s chevron-enter; 
    }
  }

  form{
    z-index: 100;
    display: grid;
    grid-template-columns: repeat(0, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 40px;
    text-align: left;
    font-size: 1rem!important;
    text-transform: uppercase;
    input{
      max-height: 71px;
      height: 100%;
      width: 100%;
      padding-left: 30px;
      word-break: break-word;
      border: none;
      border-radius: 8px 0 8px 8px;
      box-shadow: 0 15px 35px rgba(31, 110, 227, 0.25);
    }
    button[type=submit]{
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      font-weight: 700;
      border: none;
      color: white;
      width: 180px;
      height: 75px!important;
      border-radius: 8px 0 8px 8px;
      box-shadow: 0 30px 35px rgba(31, 110, 227, 0.25);
      background-color: #2fc9ef;
      ion-icon{
        padding-left: 10px;
        font-size: 32px;
        }
    }
    textarea{
      resize: none;
      width: 100%;
      max-height:213px;
      height: 100%;
      padding-left: 30px;
      padding-top: 20px;
      border: none;
      border-radius: 8px 0 8px 8px;
      box-shadow: 0 15px 35px rgba(31, 110, 227, 0.25);
    }
    .form-group{
      label{
        padding-bottom: 10px;
        font-weight: 700;
      }
      &_name{
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 2;
      }
      &_email{
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: 2;
      }
      &_message{
        grid-column-start: 2;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 3;
      }
      &_name, &_email, &_message{
        padding: 15px 0 35px;
      }
    }
    button[type=submit]{
      justify-self: end;
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 3;
      grid-row-end: 3;
    }
  }
}
@keyframes chevron-enter {
  0% { clip-path: polygon(-25% 0%, 0% 50%, -25% 100%, -100% 100%, -75% 50%, -100% 0%); }
  75% { clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%); }
  100% { clip-path: polygon(100% 0%, 100% 50%, 100% 100%, 0% 100%, 0% 50%, 0% 0%); }
}
@keyframes chevron-leave {
  0% { clip-path: polygon(100% 0%, 100% 50%, 100% 100%, 0% 100%, 0% 50%, 0% 0%); }
  25% { clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%); }
  100% { clip-path: polygon(175% 0%, 200% 50%, 175% 100%, 100% 100%, 125% 50%, 100% 0%) }
}
@media (max-width: 1400px){
  .contact {
    padding: 0 150px 0 25px;
    &_box{
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  
      background-size: auto 100%;
    &_map{
      &_container{
      }
    }
    } 
  }
  form{
    grid-template-columns: max-content!important;
    input{}
    textarea{}
    .form-group{
      label{}
      &_name{
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end:1;
      }
      &_email{
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: 2;
      }
      &_message{
        grid-column-start: 1!important;
        grid-column-end: 1!important;
        grid-row-start: 3!important;
        grid-row-end: 5!important;
      }
    }

    button[type=submit]{
      grid-column-start: 1!important;
      grid-column-end: 2!important;
      grid-row-start: 5!important;
      grid-row-end: 5!important;
    }
  }
}
@media (max-width: 1024px){
  .contact{

    padding: 0 100px 50px;
    &_box{
      overflow: unset;
      padding-left: unset;
      padding-right: unset;
      padding-bottom: unset;
    }
    &_text{
      text-align: center;
    }
    &_map{
      height: 590px;
      transform: translateY(15%);
      &_container{
        position: unset;
        align-items: center;
        height: 100%;
        max-width: 525px;
        width: 100%;
        top: unset;
        right: unset;
      }
      width: 100%;
      &__wrapper{
        z-index: -1;
        background-image: url('../../public/images/map-position.png');
        background-position: 0px -33px;
        width: 100%;
        height: 100%;
        transition: 0s;
        opacity: 0;
        transition: 1s;

      }
    }
  }
}

</style>